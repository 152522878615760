import React from 'react';
import { MapContainer } from 'react-leaflet';
import { TileLayer } from 'react-leaflet';
import { Marker } from 'react-leaflet';
import { Popup } from 'react-leaflet';
import Footer from './Footer';
import { RiMailSendFill } from "react-icons/ri";

export default function Contact() {
  const position = [36.513757, 52.665743];
  return (
    <section>
      <section className='contact-us-info-container'>
        <section className='message-sending-form-container'>
          <form>
            <div className="form-group">
              <label htmlFor="exampleInputEmail1" style={{marginBottom: '15px'}}>نام و نام خانوادگی</label>
              <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{marginBottom: '10px'}} />
            </div>
            <div className="form-group">
              <label htmlFor="exampleInputEmail1" style={{marginBottom: '15px'}}>آدرس ایمیل</label>
              <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{marginBottom: '10px'}} />
            </div>
            <div className="form-group">
              <label htmlFor="exampleInputEmail1" style={{marginBottom: '15px'}}>تلفن همراه</label>
              <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{marginBottom: '10px'}} />
            </div>
            <div className="form-group">
              <label htmlFor="exampleInputPassword1" style={{marginBottom: '15px'}}>متن پیام</label>
              <textarea className="form-control" aria-label="With textarea" style={{marginBottom: '10px'}}></textarea>
            </div>
            <button className="contact-form-btn flex-center">
              ارسال پیام
              <RiMailSendFill />
            </button>
          </form>
        </section>
        <div className='wall'></div>
        <section className='infos-container'>
          <section className='infos-details'>
            <span>تلفن : </span>
            <p>09300190663 | 01132143716</p>
            <span>ایمیل : </span>
            <p>amiraghajan78@gmail.com</p>
            <span>آدرس : </span>
            <p>مازندران - بابل - بابل</p>
            <span>ساعت و روز های کاری : </span>
            <p>8 صبح تا 8 شب | شنبه تا پنجشنبه</p>
          </section>
          <div className='contact-accordion-container'>
            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                    پرسش های متداول از لورم ایپسوم 1
                  </button>
                </h2>
                <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                    لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                    پرسش های متداول از لورم ایپسوم 2
                  </button>
                </h2>
                <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                    لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                    پرسش های متداول از لورم ایپسوم 3
                  </button>
                </h2>
                <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                    لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
      <section className='contact-us-map-container'>
        <section className='map-container'>
          <MapContainer center={position} zoom={15} scrollWheelZoom={false}>
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker position={position}>
              <Popup>
                مازندران - بابل - جاده شهید صالحی - بعد از پل محمد حسن خان - درزیکلا آخوندی پایین - پلاک 565.
              </Popup>
            </Marker>
          </MapContainer>
        </section>
      </section>
      <Footer />
    </section>
  )
}
