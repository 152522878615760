import React, { useState } from 'react';
import { IoSearchSharp } from "react-icons/io5";
import { GrBasket } from "react-icons/gr";
import { FaRegUser } from "react-icons/fa6";
import { NavLink } from 'react-router-dom';
import { IoHomeOutline } from "react-icons/io5";
import { LiaStoreAltSolid } from "react-icons/lia";
import { MdOutlineContactPhone } from "react-icons/md";
import { LuContact } from "react-icons/lu";
import { RxHamburgerMenu } from "react-icons/rx";
import { TfiClose } from "react-icons/tfi";
import { FaTrashCan } from "react-icons/fa6";
import { AiOutlineStop } from "react-icons/ai";

export default function Header() {
    const [isShowMenu, setIsShowMenu] = useState(false);
    const toggleHandler = () => {
        setIsShowMenu(!isShowMenu);
    };

    const [isShowBasket, setIsShowBasket] = useState(false);
    const basketHandler = () => {
        setIsShowBasket(true);
    };
  return (
    <>
        <header className='desktop-header'>
            <section className='top-header flex-center'>
                <img alt='' src='../images/desktop-header-logo.png' />
                <div className='search-box'>
                    <button className='search-btn'><IoSearchSharp /></button>
                    <input type='text' placeholder='جستجو' className='input-search position-center' />
                </div>
                <div className='header-btn-container flex-center'>
                    <button className='basket-btn' onClick={basketHandler}>
                        <span className='product-count'>0</span>
                        <i><GrBasket /></i>
                    </button>
                    <button className='panel-btn'>
                        <span className='panel-title'>ورود | ثبت نام</span>
                        <i><FaRegUser /></i>
                    </button>
                </div>
            </section>
            <section className='bottom-header'>
                <nav className='header-navbar'>
                    <ul className='header-menu flex-center'>
                        <li>
                            <NavLink to="/" className={(link) => link.isActive ? 'active' : ''}>
                                <i><IoHomeOutline /></i>
                                خانه
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/store">
                                <i><LiaStoreAltSolid /></i>
                                فروشگاه
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/about">
                                <i><LuContact /></i>
                                درباره ما
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/contact">
                                <i><MdOutlineContactPhone /></i>
                                تماس با ما
                            </NavLink>
                        </li>
                    </ul>
                </nav>
            </section>
        </header>
        <header className='mobile-header'>
            <section className='hamburger-menu-btn-container flex-center'>
                <button className='hamburger-btn' onClick={toggleHandler}>
                    {
                        isShowMenu ? <TfiClose /> : <RxHamburgerMenu />
                    }
                </button>
            </section>
            <section className={`hamburger-menu ${isShowMenu ? 'show' : 'hide'}`}>
                <nav className='mobile-navbar'>
                    <ul className='mobile-menu'>
                        <div className='mobile-search-bar-container flex-center'>
                            <button className='mobile-search-btn'><IoSearchSharp /></button>
                            <input type='text' placeholder='جستجو' className='mobile-search-input' />
                        </div>
                        <hr style={{margin: '20px 0'}} />
                        <li>
                            <NavLink to="/" className={(link) => link.isActive ? 'mobile-active' : ''}>
                                <i><IoHomeOutline /></i>
                                خانه
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/store">
                                <i><LiaStoreAltSolid /></i>
                                فروشگاه
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/about">
                                <i><LuContact /></i>
                                درباره ما
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/contact">
                                <i><MdOutlineContactPhone /></i>
                                تماس با ما
                            </NavLink>
                        </li>
                        <hr style={{margin: '20px 0'}} />
                        <div className='mobile-panel-btn-container flex-center'>
                            <button className='mobile-panel-btn'>
                                ورود | ثبت نام
                                <i><FaRegUser /></i>
                            </button>
                        </div>
                    </ul>
                </nav>
            </section>
            <section className='mobile-basket-btn-container flex-center'>
                <button className='mobile-basket-btn'>
                    <span className='mobile-product-count'>0</span>
                    <i className='position-center'><GrBasket /></i>
                </button>
            </section>
            <section className='mobile-logo-container flex-center'>
                <img alt='' src='../images/mobile-header-logo.png' />
            </section>
        </header>
        <section className={`basket-container ${isShowBasket ? 'show' : 'hide'}`}>
            <header className='basket-header flex-center'>
                <h4>سبد خرید</h4>
                <button className='basket-close-btn' onClick={() => {
                    setIsShowBasket(false);
                }}>
                    <TfiClose />
                </button>
            </header>
            <section className='basket-body'>
                <div className='basket-item flex-center'>
                    <button className='basket-item-delete-btn flex-center'>
                        حذف
                        <FaTrashCan />
                    </button>
                    <span className='basket-item-count'>0</span>
                    <span className='basket-item-price'>تومان 70000000</span>
                    <span className='basket-item-name'>Galaxy S24 Ultra</span>
                    <img alt='' src='../images/mobile1.webp' className='basket-item-image' />
                </div>
            </section>
            <section className='basket-footer flex-center'>
                <button className='basket-empty-btn flex-center'>
                    تخلیه سبد
                    <AiOutlineStop />
                </button>
                <div className='basket-items-count-container'>
                    <span>تعداد محصول : </span>
                    <span>عدد 0</span>
                </div>
                <div className='basket-total-price-container'>
                    <span>قیمت کل : </span>
                    <span>تومان 0</span>
                </div>
            </section>
        </section>
    </>
  )
}
