// amazingOffer => pishnahade shegeft angiz
// bestSellingProducts => por forosh tarin mahsolat

const productsData = [
    {id: 1, category: 'موبایل', name: 'Galaxy S24 Ultra', price: 58_000_000, image: '../images/mobile1.webp', brand: 'samsung', amazingOffer: false, bestSellingProducts: true, discount: 0},
    {id: 2, category: 'موبایل', name: 'Galaxy A15', price: 8_500_000, image: '../images/mobile2.webp', brand: 'samsung', amazingOffer: true, bestSellingProducts: false, discount: 7_735_000},
    {id: 3, category: 'موبایل', name: 'Galaxy A55', price: 21_300_000, image: '../images/mobile3.webp', brand: 'samsung', amazingOffer: true, bestSellingProducts: true, discount: 19_383_000},
    {id: 4, category: 'موبایل', name: 'Xiaomi 14', price: 51_000_000, image: '../images/mobile4.webp', brand: 'xiaomi', amazingOffer: false, bestSellingProducts: false, discount: 0},
    {id: 5, category: 'موبایل', name: 'Redmi Note 13 Pro', price: 19_000_000, image: '../images/mobile5.webp', brand: 'xiaomi', amazingOffer: false, bestSellingProducts: false, discount: 0},
    {id: 6, category: 'موبایل', name: 'Huawei P20 Lite', price: 12_000_000, image: '../images/mobile6.png', brand: 'huawei', amazingOffer: true, bestSellingProducts: false, discount: 10_920_000},
    {id: 7, category: 'موبایل', name: 'iPhone 13 CH', price: 39_000_000, image: '../images/mobile7.webp', brand: 'apple', amazingOffer: false, bestSellingProducts: false, discount: 0},
    {id: 8, category: 'موبایل', name: 'iPhone 15 Pro Max', price: 70_000_000, image: '../images/mobile8.webp', brand: 'apple', amazingOffer: false, bestSellingProducts: true, discount: 0},
    {id: 9, category: 'موبایل', name: 'iphone 14 Pro', price: 60_000_000, image: '../images/mobile9.webp', brand: 'apple', amazingOffer: true, bestSellingProducts: true, discount: 54_600_000},
    {id: 10, category: 'موبایل', name: 'Galaxy S23 FE', price: 28_400_000, image: '../images/mobile10.webp', brand: 'samsung', amazingOffer: false, bestSellingProducts: false, discount: 0},
    {id: 11, category: 'تبلت', name: 'Galaxy Tab A9-4G', price: 9_000_000, image: '../images/tablet1.webp', brand: 'samsung', amazingOffer: false, bestSellingProducts: true, discount: 0},
    {id: 12, category: 'تبلت', name: 'Galaxy Tab A8 10.5 SM-X205', price: 16_700_000, image: '../images/tablet2.webp', brand: 'samsung', amazingOffer: false, bestSellingProducts: false, discount: 0},
    {id: 13, category: 'تبلت', name: 'Galaxy Tab S6 Lite 2022 Wi-Fi', price: 24_400_000, image: '../images/tablet3.webp', brand: 'samsung', amazingOffer: false, bestSellingProducts: false, discount: 0},
    {id: 14, category: 'تبلت', name: 'Redmi Pad SE', price: 12_000_000, image: '../images/tablet4.webp', brand: 'xiaomi', amazingOffer: false, bestSellingProducts: false, discount: 0},
    {id: 15, category: 'تبلت', name: 'Pad 6', price: 17_800_000, image: '../images/tablet5.webp', brand: 'xiaomi', amazingOffer: false, bestSellingProducts: false, discount: 0},
    {id: 16, category: 'تبلت', name: 'Redmi Pad SE', price: 10_000_000, image: '../images/tablet6.webp', brand: 'xiaomi', amazingOffer: false, bestSellingProducts: true, discount: 0},
    {id: 17, category: 'تبلت', name: 'iPad Mini 6th Generation 2021 Wi-Fi', price: 25_900_000, image: '../images/tablet7.webp', brand: 'apple', amazingOffer: false, bestSellingProducts: false, discount: 0},
    {id: 18, category: 'تبلت', name: 'iPad 9th Generation', price: 14_700_000, image: '../images/tablet8.webp', brand: 'apple', amazingOffer: false, bestSellingProducts: false, discount: 0},
    {id: 19, category: 'تبلت', name: 'iPad 2022', price: 20_000_000, image: '../images/tablet9.webp', brand: 'apple', amazingOffer: true, bestSellingProducts: false, discount: 18_200_000},
    {id: 20, category: 'تبلت', name: 'iPad Air 2024 M2 Wi-Fi', price: 37_000_000, image: '../images/tablet10.webp', brand: 'apple', amazingOffer: false, bestSellingProducts: false, discount: 0},
    {id: 21, category: 'لپ تاپ', name: 'HP fd0336nia-15-i3 1315U', price: 24_600_000, image: '../images/laptop1.webp', brand: 'hp', amazingOffer: false, bestSellingProducts: false, discount: 0},
    {id: 22, category: 'لپ تاپ', name: 'HP 255-G8-E R5 32GB 1SSD', price: 26_000_000, image: '../images/laptop2.webp', brand: 'hp', amazingOffer: false, bestSellingProducts: false, discount: 0},
    {id: 23, category: 'لپ تاپ', name: 'HP Victus 16 Gaming', price: 72_000_000, image: '../images/laptop3.webp', brand: 'hp', amazingOffer: false, bestSellingProducts: false, discount: 0},
    {id: 24, category: 'لپ تاپ', name: 'Asus ExpertBook', price: 36_000_000, image: '../images/laptop4.webp', brand: 'asus', amazingOffer: true, bestSellingProducts: false, discount: 32_760_000},
    {id: 25, category: 'لپ تاپ', name: 'Asus Vivobook 15', price: 32_000_000, image: '../images/laptop5.webp', brand: 'asus', amazingOffer: false, bestSellingProducts: false, discount: 0},
    {id: 26, category: 'لپ تاپ', name: 'Asus TUF Gaming F15', price: 63_000_000, image: '../images/laptop6.webp', brand: 'asus', amazingOffer: false, bestSellingProducts: false, discount: 0},
    {id: 27, category: 'لپ تاپ', name: 'MacBook Air MGN63 2020', price: 42_000_000, image: '../images/laptop7.webp', brand: 'apple', amazingOffer: false, bestSellingProducts: false, discount: 0},
    {id: 28, category: 'لپ تاپ', name: 'MacBook Air 2022', price: 56_000_000, image: '../images/laptop8.webp', brand: 'apple', amazingOffer: false, bestSellingProducts: false, discount: 0},
    {id: 29, category: 'لپ تاپ', name: 'MacBook Air MLY33 2022', price: 56_700_000, image: '../images/laptop9.webp', brand: 'apple', amazingOffer: false, bestSellingProducts: false, discount: 0},
    {id: 30, category: 'لپ تاپ', name: 'Lenovo IdeaPad Slim 3', price: 32_700_000, image: '../images/laptop10.webp', brand: 'lenovo', amazingOffer: false, bestSellingProducts: true, discount: 0},
    {id: 31, category: 'کیس کامپیوتر', name: 'SABIT CG-22', price: 18_000_000, image: '../images/janebi1.webp', amazingOffer: false, bestSellingProducts: true, discount: 0},
    {id: 32, category: 'کیس کامپیوتر', name: 'ITCO CG 102', price: 21_000_000, image: '../images/janebi2.webp', amazingOffer: false, bestSellingProducts: false, discount: 0},
    {id: 33, category: 'مانیتور', name: 'NDM-22VN', price: 3_800_000, image: '../images/janebi3.webp', amazingOffer: true, bestSellingProducts: false, discount: 3_458_000},
    {id: 34, category: 'مانیتور', name: 'Samsung LS27', price: 5_300_000, image: '../images/janebi4.webp', amazingOffer: false, bestSellingProducts: false, discount: 0},
    {id: 35, category: 'شارژر', name: 'PA-1700-02', price: 600_000, image: '../images/janebi5.webp', amazingOffer: false, bestSellingProducts: false, discount: 0},
    {id: 36, category: 'شارژر', name: 'EP-T4510', price: 400_000, image: '../images/janebi6.webp', amazingOffer: false, bestSellingProducts: false, discount: 0},
    {id: 37, category: 'کول پد', name: 'پایه خنک کننده لپ تاپ یوکام', price: 1_000_000, image: '../images/janebi7.webp', amazingOffer: true, bestSellingProducts: false, discount: 910_000},
    {id: 38, category: 'کول پد', name: 'پایه خنک کننده لپ تاپ تسکو', price: 1_600_000, image: '../images/janebi8.webp', amazingOffer: false, bestSellingProducts: false, discount: 0},
    {id: 39, category: 'ماوس', name: 'ماوس مخصوص بازی اونیکوما', price: 500_000, image: '../images/janebi9.webp', amazingOffer: false, bestSellingProducts: false, discount: 0},
    {id: 40, category: 'ماوس', name: 'ماوس مخصوص بازی بلادی', price: 2_000_000, image: '../images/janebi10.webp', amazingOffer: false, bestSellingProducts: false, discount: 0},
    {id: 41, category: 'کیبورد', name: 'کیبورد بیاند مدل BK-3870', price: 450_000, image: '../images/janebi11.webp', amazingOffer: false, bestSellingProducts: false, discount: 0},
    {id: 42, category: 'کیبورد', name: 'کیبورد تسکو مدل TK 8019', price: 430_000, image: '../images/janebi12.webp', amazingOffer: false, bestSellingProducts: false, discount: 0},
    {id: 43, category: 'ماوس پد', name: 'موس پد مخصوص بازی میزاکس مدل B202', price: 100_000, image: '../images/janebi13.webp', amazingOffer: false, bestSellingProducts: false, discount: 0},
    {id: 44, category: 'ماوس پد', name: 'ماوس پد طرح WALL-E مدل MP2736', price: 60_000, image: '../images/janebi14.webp', amazingOffer: false, bestSellingProducts: false, discount: 0},
    {id: 45, category: 'هولدر موبایل', name: 'سه پایه نگهدارنده گوشی موبایل کروماکی', price: 1_500_000, image: '../images/janebi15.webp', amazingOffer: false, bestSellingProducts: false, discount: 0},
    {id: 46, category: 'هولدر موبایل', name: 'هولدر نگهدارنده گوشی موبایل مدل CAR HOLDER', price: 500_000, image: '../images/janebi16.webp', amazingOffer: false, bestSellingProducts: false, discount: 0},
    {id: 47, category: 'هدفون', name: 'هدفون بلوتوثی ریمکس', price: 2_400_000, image: '../images/janebi17.webp', amazingOffer: true, bestSellingProducts: false, discount: 2_184_000},
    {id: 48, category: 'هدفون', name: 'هدفون بلوتوثی کیو سی وای', price: 670_000, image: '../images/janebi18.webp', amazingOffer: false, bestSellingProducts: false, discount: 0},
    {id: 49, category: 'ایرپاد', name: 'ایرپاد بلوتوثی مدل airpadspro generation air-ro3 jb-I', price: 500_000, image: '../images/janebi19.webp', amazingOffer: false, bestSellingProducts: false, discount: 0},
    {id: 50, category: 'ایرپاد', name: 'ایرپاد بلوتوثی مدل ا8425 airpods generation air-ro2', price: 600_000, image: '../images/janebi20.webp', amazingOffer: false, bestSellingProducts: false, discount: 0},
    {id: 51, category: 'اسپیکر کامپیوتر', name: 'اسپیکر بلوتوثی دسکتاپ کیسونلی مدل AC-9002BT', price: 800_000, image: '../images/janebi21.webp', amazingOffer: false, bestSellingProducts: false, discount: 0},
    {id: 52, category: 'اسپیکر کامپیوتر', name: 'اسپیکر بلوتوثی دسکتاپ کیسونلی مدل U-2900BT', price: 850_000, image: '../images/janebi22.webp', amazingOffer: false, bestSellingProducts: false, discount: 0},
    {id: 53, category: 'اسپیکر خانگی', name: 'پخش کننده خانگی ال جی مدل on7', price: 14_000_000, image: '../images/janebi23.png', amazingOffer: true, bestSellingProducts: false, discount: 12_740_000},
    {id: 54, category: 'اسپیکر خانگی', name: 'پخش کننده خانگی سونی مدل MHC-V13', price: 18_000_000, image: '../images/janebi24.webp', amazingOffer: false, bestSellingProducts: false, discount: 0},
    {id: 55, category: 'گلس گوشی', name: 'گلس استار مدل SUPERXS', price: 100_000, image: '../images/janebi25.webp', amazingOffer: false, bestSellingProducts: false, discount: 0},
    {id: 56, category: 'گلس گوشی', name: 'گلس مدل SUPERD', price: 100_000, image: '../images/janebi26.webp', amazingOffer: true, bestSellingProducts: false, discount: 91_000},
    {id: 57, category: 'قاب گوشی', name: 'گارد گوشی طرح فانتزی کد Dimo-171', price: 120_000, image: '../images/janebi27.webp', amazingOffer: false, bestSellingProducts: false, discount: 0},
    {id: 58, category: 'قاب گوشی', name: 'گارد گوشی طرح سیاره کد TPU-195', price: 230_000, image: '../images/janebi28.webp', amazingOffer: true, bestSellingProducts: false, discount: 209_300},
    {id: 59, category: 'پاوربانک', name: 'پاوربانک پرووان مدل PPB5207PD', price: 1_200_000, image: '../images/janebi29.webp', amazingOffer: true, bestSellingProducts: true, discount: 1_092_000},
    {id: 60, category: 'پاوربانک', name: 'پاوربانک شیائومی مدل Redmi PB200LZM', price: 1_800_000, image: '../images/janebi30.webp', amazingOffer: false, bestSellingProducts: false, discount: 0},
    {id: 61, category: 'مودم', name: 'مودم روتر ADSL2 Plus بی‌ سیم N300 دی-لینک مدل DSL-2740U', price: 2_000_000, image: '../images/janebi31.webp', amazingOffer: false, bestSellingProducts: false, discount: 0},
    {id: 62, category: 'مودم', name: 'مودم روتر ADSL2 Plus بی‌سیم N300 دی-لینک مدل DSL-2750U', price: 1_400_000, image: '../images/janebi32.webp', amazingOffer: false, bestSellingProducts: false, discount: 0},
    {id: 63, category: 'پرینتر', name: 'پرینتر چندکاره لیزری اچ پی مدل Laser MFP 135w', price: 11_300_000, image: '../images/printer1.webp', amazingOffer: false, bestSellingProducts: false, discount: 0},
    {id: 64, category: 'پرینتر', name: 'پرینتر چند کاره کانن مدل PIXMA MG2545s', price: 4_200_000, image: '../images/printer2.webp', amazingOffer: false, bestSellingProducts: false, discount: 0},
    {id: 65, category: 'باتری', name: 'باتری لپ تاپ 6 سلولی مدل F0092 مناسب برای لپ تاپ دل ', price: 1_160_000, image: '../images/battery1.webp', amazingOffer: false, bestSellingProducts: false, discount: 0},
    {id: 66, category: 'باتری', name: 'باتری موبایل مدل A10 ظرفیت 3400 میلی آمپر', price: 270_000, image: '../images/battery2.webp', amazingOffer: false, bestSellingProducts: false, discount: 0},
    {id: 67, category: 'هارد اکسترنال', name: 'هارد اکسترنال مدل دبلیو دی المنت ظرفیت 1.5 ترابایت', price: 2_300_000, image: '../images/hard1.webp', amazingOffer: false, bestSellingProducts: false, discount: 0},
    {id: 68, category: 'هارد اکسترنال', name: 'هارد اکسترنال ای دیتا مدل HD710 Pro ظرفیت 1 ترابایت', price: 4_300_000, image: '../images/hard2.webp', amazingOffer: false, bestSellingProducts: false, discount: 0},
    {id: 69, category: 'حافظه جانبی و USB', name: 'فلش مموری اوسکو مدل 006U USB 3.0 ظرفیت 64 گیگابایت', price: 300_000, image: '../images/memory1.webp', amazingOffer: false, bestSellingProducts: false, discount: 0},
    {id: 70, category: 'حافظه جانبی و USB', name: 'کارت حافظه microSDXC سن دیسک مدل Ultra A1', price: 630_000, image: '../images/memory2.webp', amazingOffer: false, bestSellingProducts: false, discount: 0},
];

export default productsData;
