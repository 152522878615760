import React from 'react';
import { FaTelegram } from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";
import { FaInstagram } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaFacebookSquare } from "react-icons/fa";

export default function Footer() {
  return (
      <footer>
        <section className='top-footer'>
          <div className='footer-sec-1'>
            <h6>تکنو استور</h6>
            <p>
            لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد 
            </p>
          </div>
          <div className='footer-sec-2'>
            <h6>دسترسی سریع</h6>
            <a href='http://localhost:3000'>خانه</a>
            <a href='http://localhost:3000/store'>فروشگاه</a>
            <a href='http://localhost:3000/about'>درباره ما</a>
            <a href='http://localhost:3000/contact'> تماس با ما</a>
          </div>
          <div className='footer-sec-3'>
            <h6>با ما در ارتباط باشید</h6>
            <div className='social-media-icons-container flex-center'>
              <a href='http://localhost:3000'><i className='telegram'><FaTelegram /></i></a>
              <a href='http://localhost:3000'><i className='whatsapp'><IoLogoWhatsapp /></i></a>
              <a href='http://localhost:3000'><i className='instagram'><FaInstagram /></i></a>
              <a href='http://localhost:3000'><i className='twitter'><FaTwitter /></i></a>
              <a href='http://localhost:3000'><i className='facebook'><FaFacebookSquare /></i></a>
            </div>
          </div>
          <div className='footer-sec-4'>
            <h6>مجوز ها</h6>
            <div className='permissions-image-container flex-center'>
              <a href='https://enamad.ir/'><img alt='' src='../images/e-namad.png' className='footer-img' /></a>
              <a href='https://www.zarinpal.com/'><img alt='' src='../images/zarin-pal.png' className='footer-img' /></a>
            </div>
          </div>
        </section>
        <section className='bottom-footer'>
          <p className='copyright-law'>
            تمام حقوق مادی و معنوی متعلق به سایت <a href='http://localhost:3000'>تکنو استور</a> می باشد.
          </p>
        </section>
      </footer>
  )
}
