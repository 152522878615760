import Home from './Components/Home';
import Store from './Components/Store';
import About from './Components/About';
import Contact from './Components/Contact';
import Product from './Components/Product';

let routes = [
    {path: '/', element: <Home />},
    {path: '/store', element: <Store />},
    {path: '/products/:productID', element: <Product />},
    {path: '/about', element: <About />},
    {path: '/contact', element: <Contact />},
];

export default routes;
