import './App.css';
import React, { useEffect, useState } from 'react';
import { useRoutes } from 'react-router-dom';
import routes from './routes';
import Header from './Components/Header';
import { LuArrowUpFromDot } from "react-icons/lu";

export default function App() {
  let router = useRoutes(routes);
  const [scrollPosition, setScrollPosition] = useState(0);
  const handlerScroll = () => {
    const positionY = window.pageYOffset;
    setScrollPosition(positionY);
  };
  useEffect(() => {
    window.addEventListener('scroll', handlerScroll);
    return () => {
      window.removeEventListener('scroll', handlerScroll);
    };
  }, []);
  // console.log("Scroll Position : ", scrollPosition);
  const goToTopPage = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  };
  return (
    <>
      <Header />
      <button className={`scroll-top-btn ${scrollPosition >= 200 ? 'show' : 'hide'}`} onClick={goToTopPage}><LuArrowUpFromDot /></button>
      {router}
    </>
  )
}
