import React, { useEffect, useState } from 'react';
import Footer from './Footer';
import { ImFilter } from "react-icons/im";
import productsData from '../Data/ProductsData';
import Products from './Products';

export default function Store() {
  const getInitialState = () => {
    const value = 'انتخاب کنید...';
    return value;
  };
  const [value, setValue] = useState(getInitialState);
  const selectBoxHandler = (e) => {
    let mainValue =  e.target.value;
    setValue(mainValue);
    // console.log(mainValue);
  };

  const [allProducts, setAllProducts] = useState(productsData);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginated, setPaginated] = useState([]);
  let itemCount = 9;
  let pagesNumbers;
  useEffect(() => {
    let endIndex = itemCount * currentPage;
    let startIndex = endIndex - itemCount;
    let allShownProducts = allProducts.slice(startIndex, endIndex);
    setPaginated(allShownProducts);
  }, [currentPage]);
  const changePaginate = (newPage) => {
    setCurrentPage(newPage);
  };
  const pagesCount = Math.ceil(allProducts.length / itemCount);
  pagesNumbers = Array.from(Array(pagesCount).keys());
  // console.log(pagesCount);
  // console.log(pagesNumbers);

  const [filterProduct, setFilterProduct] = useState([]);
  const filterHandler = () => {
    let filteredProducts = allProducts.filter(product => product.category == value);
    if (filteredProducts && filteredProducts.length > 0) {
      setFilterProduct(filteredProducts);
      console.log('Filtered Products : ', filterProduct);
    };
  };
  return (
    <div>
      <main className='content-container flex-center'>
        <section className='side-bar'>
          <div className='mobile-filter-bg'>
            <div className='mobile-filter-container flex-center'>
              <span>فیلتر محصولات : </span>
              <select value={value} onChange={selectBoxHandler}>
                <option>انتخاب کنید...</option>
                <option>موبایل</option>
                <option>تبلت</option>
                <option>لپ تاپ</option>
                <option>کیس کامپیوتر</option>
                <option>مانیتور</option>
                <option>شارژر</option>
                <option>کول پد</option>
                <option>ماوس</option>
                <option>کیبورد</option>
                <option>ماوس پد</option>
                <option>هولدر موبایل</option>
                <option>هدفون</option>
                <option>ایرپاد</option>
                <option>اسپیکر کامپیوتر</option>
                <option>اسپیکر خانگی</option>
                <option>گلس گوشی</option>
                <option>قاب گوشی</option>
                <option>پاوربانک</option>
                <option>مودم</option>
                <option>باتری</option>
                <option>هارد اکسترنال</option>
                <option>حافظه جانبی و USB</option>
                <option>پرینتر</option>
              </select>
              <button className='mobile-filter-btn' onClick={filterHandler}>
                اعمال فیلتر
                <i><ImFilter /></i>
              </button>
            </div>
          </div>
          <h5 className='filter-h5' style={{width: '100%', height: '60px', textAlign: 'center', lineHeight: '60px'}}>فیلتر محصولات</h5>
          <label className='label'>دسته بندی محصول :</label>
          <select value={value} onChange={selectBoxHandler} className='filter-select'>
            <option>انتخاب کنید...</option>
            <option>موبایل</option>
            <option>تبلت</option>
            <option>لپ تاپ</option>
            <option>کیس کامپیوتر</option>
            <option>مانیتور</option>
            <option>شارژر</option>
            <option>کول پد</option>
            <option>ماوس</option>
            <option>کیبورد</option>
            <option>ماوس پد</option>
            <option>هولدر موبایل</option>
            <option>هدفون</option>
            <option>ایرپاد</option>
            <option>اسپیکر کامپیوتر</option>
            <option>اسپیکر خانگی</option>
            <option>گلس گوشی</option>
            <option>قاب گوشی</option>
            <option>پاوربانک</option>
            <option>مودم</option>
            <option>باتری</option>
            <option>هارد اکسترنال</option>
            <option>حافظه جانبی و USB</option>
            <option>پرینتر</option>
          </select>
          <label className='label' style={{marginTop: '20px'}}>قیمت محصول : </label>
          <label className='label'>قیمت از :</label>
          <input type='text' className='price-input' />
          <label className='label'>قیمت تا :</label>
          <input type='text' className='price-input' />
          <button className='filter-btn' onClick={filterHandler}>
            اعمال فیلتر
            <i><ImFilter /></i>
          </button>
        </section>
        <section className='main-content'>
          <section className='products-container flex-center'>
            {
              paginated.map(product => (
                <Products key={product.id} {...product} />
              ))
            }
          </section>
          <section className='pagination-container flex-center'>
            <div className='pagination-container-menu'>
              {
                pagesNumbers.map((pageNumber) => (
                  <span key={pageNumber + 1} onClick={() => changePaginate(pageNumber + 1)} 
                  className={`${pageNumber + 1 === currentPage ? 'pagination-active' : ''}`}>{pageNumber + 1}</span>
                ))
              }
            </div>
          </section>
        </section>
      </main>
      <Footer />
    </div>
  )
}
