import React from 'react';
import Footer from './Footer';

export default function About() {
  return (
    <div>
      <section className='about-content-container'>
        <div className='profile-container'>
          <div className='instagram-border position-center'>
            <div className='white-bg position-center'>
              <div className='profile-image-container position-center'>
                <img className='profile-image' alt='' src='../images/profile-image.png' />
                <div className='profile-image-cover position-center'></div>
              </div>
            </div>
          </div>
        </div>
        <div className='about-description-container'>
          <div className='description-header'>
            <h4>فروشگاه اینترنتی تکنو استور</h4>
          </div>
          <p>
          لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت 
          چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است،
           و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای
           زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت
           بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد، در این صورت
           می توان امید داشت که تمام و دشواری موجود در ارائه راهکارها، و شرایط سخت تایپ به پایان رسد و زمان مورد نیاز 
          شامل حروفچینی دستاوردهای اصلی، و جوابگوی سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.
          </p>
        </div>
      </section>
      <Footer />
    </div>
  )
}
