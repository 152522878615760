import React from 'react';
import { SlBasket } from "react-icons/sl";
import { FaPlus } from "react-icons/fa6";
import { Link } from 'react-router-dom';

export default function Products(props) {
  return (
    <div className='main-product-cart' key={props.id}>
        <img alt='' src={props.image} className='main-product-image' />
        <span className='main-product-name'>{props.name}</span>
        <span className='main-product-price'>تومان {props.price}</span>
        <div className='product-cart-btn-container flex-center'>
        <Link to={`/products/${props.id}`}>
            <button className='info-btn'>
                <i><FaPlus /></i>
                <label className='product-tooltip'>
                جزئیات محصول
                <span className='tooltip-triangle'></span>
                </label>
            </button>
        </Link>
        <button className='add-to-cart-btn'>
            افزودن به سبد
            <i><SlBasket /></i>
        </button>
        </div>
    </div>
  )
}
